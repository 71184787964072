html, body, #root {
  height: 100%;
}

body {
  background-image: url('./pattern.png');
}

::-webkit-scrollbar { 
  width: 10px;
  background-color: #dbdbdb;
  height: 100%;
  border-radius: 3px;
  position: absolute;
}
::-webkit-scrollbar-button { 

}
::-webkit-scrollbar-track        { /* 3 */ }
::-webkit-scrollbar-track-piece  { /* 4 */ }
::-webkit-scrollbar-thumb { 
  width: 10px;
  background-color: #A0ABAC;
  border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover { 
  background-color: #47c2dc;
}
::-webkit-scrollbar-thumb:active { 
  background-color: #1ec9a4;
}
::-webkit-scrollbar-corner       { /* 6 */ }
::-webkit-resizer                { /* 7 */ }



[class^="gds-text"] {
  color: #3B4144;
}

.main {
  background-color: white;
  box-shadow: 0px 0px 40px rgba(0,0,0, 0.1);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}

.curator-content {
  background: white;
  height: 100%;
  width: 100%;
}

.button {
  border-radius: 20px;
  background-color: #43ABE5;
  border: none;
  padding: 0.5rem 1rem;
  color: white;
  font-family: pnsemibold;
  cursor: pointer;
}

.button:hover {
  box-shadow: 0px 0px 2px #43ABE5;
  background-color: #7dc2e9;
}

.button:focus {
  outline: none;
  box-shadow: 0px 0px 4px #43ABE5;
}

.button--secondary {
  border: 1px solid #43ABE5;
  color: #43ABE5;
  background-color: transparent;
}

.title-text {
  transform: rotate(-90deg);
  transform-origin: left top 0;
  left: 1vh;
  top: 97vh;
  font-size: 14vh;
  white-space: nowrap;
  position: absolute;
}

.fixed-header {
  position: fixed;
  right: 0;
  width: 100%; /*calc(100% - 12vh);*/
  height: 12vh;
  z-index: 50;
  background-color: white;
  border-bottom: 1px solid #dfdfdf;

}

.nav-bar  {
  width: 260px;
  padding: 1rem 0;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
}

.nav-bar__inner {
  height: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.nav-bar__title, .nav-bar__logo {
  margin-right: 0.5rem;
}

.nav-bar__header {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 1rem 0 0.75rem 0;
  flex-direction: column;
}

.image-stamp {
  position: absolute;
  right: calc(50% - 50px);
  bottom: calc(50% + 25px);
  z-index: 0;
}

.mobile-header, .mobile-footer {
  z-index: 2;
  background-color: white;
  display: none;
  order: 1;
  padding: 0 1rem;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: auto;
}

.mobile-header__inner {
  padding: 1.5rem 0 0.75rem 0;
}
.mobile-footer__inner {
  padding: 1rem 0;
}

.mobile-header__inner, .mobile-footer__inner {
  display: flex;
  justify-content: flex-end;
  align-items: center;

}
.nav-bar__menu {
  height: 100%;
  overflow: auto;
  padding: 0 0.5rem 0 0;
  margin: 1rem 0 0 0;
}

.nav-bar__menu-header {
  font-size: 1rem;
  color: #025D71;
  margin: 1.25rem 0 0.125rem 50px;
  padding-bottom: 0.25rem;
  cursor: pointer;
  user-select: none;
  transition: all 150ms cubic-bezier(0.895, 0.030, 0.685, 0.220);
  border-bottom: 1px dashed #025D71;
  text-transform: uppercase;
}

.nav-bar__menu-header:hover .arrow-icon {
  color: #43ABE5;
}

.nav-bar__menu-header:hover {
  color: #43ABE5;
  border-bottom: 1px dashed #43ABE5;
}


.nav-bar__menu-header:first-child {
  margin-top: 0;
}

.nav-bar__menu-header--collapsed {
  transition: all 250ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  color: #A0ABAC;
  border-bottom: 2px dashed rgba(0,0,0,0);
}

.arrow-icon {
  transform-origin: 50% 50%;
  transition: inherit;
}

.arrow-icon--collapsed {
  transform: rotate(180deg);
  transition: inherit;
}

.nav-bar__menu-options {
  overflow: hidden;
  height: auto;
  max-height: 1000px;
  transition: all 250ms cubic-bezier(0.895, 0.030, 0.685, 0.220);
}

.nav-bar__menu-options--collapsed {
  max-height: 0;
  transition: all 250ms cubic-bezier(0.165, 0.840, 0.440, 1.000);

}


.nar-bar__menu-option {
  font-size: 0.8rem;
  color: #A0ABAC;
  padding-right: 1rem;
  margin: 0.25rem;
  cursor: pointer;
  user-select: none;
}




.nar-bar__menu-option:after {
  content: '';
  position: absolute;
  right: 0px;
  top: calc(50% - 2px);
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #A0ABAC;
}

.nar-bar__menu-option:hover {
  color: #43ABE5;
}

.nar-bar__menu-option:hover:after {
  background-color: #43ABE5;
}

.divider-horizontal {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right, transparent 50%, #A0ABAC 50%);
  background-size: 20px 100%;
}


.divider-horizontal--top {
  bottom: auto;
  top: -1px;
}

.divider-horizontal:before, .divider-horizontal:after {
  content: '';
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
  position: absolute;
}

.divider-horizontal:after {
  transform: rotate(-45deg);
  top:-2px;
  right: 0;
}

.divider-horizontal:before {
  transform: rotate(135deg);
  top:-2px;
  left: 0;
}

.divider-vertical {
  position: absolute;
  bottom: 0px;
  right: 0;
  height: 100%;
  width: 1px;
  background-image: linear-gradient(to bottom, transparent 50%, #A0ABAC 50%);
  background-size: 100% 20px;
}

.divider-vertical:before, .divider-vertical:after {
  content: '';
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
  position: absolute;
}

.divider-vertical:after {
  transform: rotate(45deg);
  bottom:0px;
  left: -2px;
}

.divider-vertical:before {
  transform: rotate(-135deg);
  top:0px;
  left: -2px;
}

.mobile-nav-input {
  padding: 0.5rem;
  width: 100%;
  border-radius: 5px;
}

.mobile-header--always-on { 
  display: block !important;
}
@media (max-width: 767px) {
  .mobile-header, .mobile-footer {
    display: block;
  }
}
